import React from "react"
import Layout from "../../components/layout"
import CommonProduct from "../../components/common/CommonProduct"
import image from "../../../static/Client/hrms.jpg"
import { IoCardOutline, IoFastFoodOutline } from "react-icons/io5"
import { FaUserPlus } from "react-icons/fa"
import { CiCalendar } from "react-icons/ci"
import { AiOutlineStar } from "react-icons/ai"
const HRMS = () => {
  const data = {
    name: "HRMS",
    description:
      "Our HR Management System (HRMS) is a comprehensive platform designed to streamline and automate all aspects of human resource management. From employee onboarding and attendance tracking to payroll management and performance evaluations, HRMS empowers organizations to manage their workforce efficiently and effectively.",
    image: image,
    features: [
      {
        id: 1,
        icon: <FaUserPlus />,
        title: "Employee Onboarding & Management",
        description:
          "Simplify the onboarding process with an organized system for adding new employees, managing their personal details, and maintaining essential documentation. Keep all employee information centralized for easy access and management.",
      },
      {
        id: 2,
        icon: <CiCalendar />,
        title: "Attendance & Leave Tracking",
        description:
          "Monitor employee attendance, manage leave requests, and track working hours seamlessly. The system automates attendance records and leave balances, ensuring accurate payroll processing and compliance with company policies.",
      },
      {
        id: 3,
        icon: <IoCardOutline />,
        title: "Payroll Management",
        description:
          "Automate salary calculations, deductions, and tax filings with our robust payroll feature. Generate payslips, manage bonuses, and ensure timely disbursement of employee payments, reducing administrative burden and errors.",
      },
      {
        id: 4,
        icon: <AiOutlineStar />,
        title: "Performance Evaluation",
        description:
          "Track employee performance through periodic reviews, goal setting, and feedback mechanisms. Generate reports to assess productivity, recognize top performers, and identify areas for improvement to foster employee growth.",
      },
    ],
  }

  return (
    <Layout>
      <CommonProduct
        name={data.name}
        description={data.description}
        image={data.image}
        features={data.features}
      />
    </Layout>
  )
}

export default HRMS
