import React from "react"
import Button from "./Button"
import { Link } from "gatsby"

const CommonProduct = ({ name, description, image, features }) => {
  return (
    <div className=" 2xl:px-[160px] sm:px-[50px] px-[10px]  gap-16 p-4 md:mt-10 mt-4">
      <div className="md:flex items-center gap-10 p-5 md:space-y-0 space-y-5">
        <div className="md:w-1/2 space-y-4">
          <h2 className="text-2xl font-bold">{name}</h2>
          <p className="">{description}</p>
          <Link to="#">
            <Button name="Know More" />
          </Link>
        </div>
        <div className="md:w-1/2 flex justify-center">
          <img src={image} alt="images" className="rounded-lg shadow-lg" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-10 md:p-5 p-3 my-10">
        {features.map(feature => (
          <div
            key={feature.id}
            className="md:p-4 p-2 rounded-lg border border-white space-y-3"
          >
            <div className="md:text-3xl text-xl bg-white text-black  rounded-full md:h-12 h-7 md:w-12 w-7 grid place-content-center place-items-center ">
              {feature.icon}
            </div>
            <h3 className="font-bold md:text-xl">{feature.title}</h3>
            <p className="">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CommonProduct
